import React, { useEffect } from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from 'react-router-dom';
import { getPlanYearSwitch, updateFilters, updateSearch, setTabIndex, selectProviderType } from 'redux/actions';
import { providerSelector } from 'redux/selectors/providerSelectors';
import { dataCategoTypeForFacilities } from '../ProviderSearch.CoreLibraries/common/filters';
import {
    HeaderFindAProvider,
    AlertContainer,
    FiltersSearchContainer,
    ProviderTypesContainer,
    ResultActions,
    SearchResults,
    Spinner,
} from '../ProviderSearch.CoreLibraries/components';
import GoTopButton from "../ProviderSearch.CoreLibraries/components/Button/GoTopButton";
import ChatContainer from '../ProviderSearch.CoreLibraries/components/Chat/ChatContainer';
import QuickSearchContainer from '../ProviderSearch.CoreLibraries/components/quickSearch/QuickSearchContainer';
import { handleFeatureFlagForChat, formatSearch, providerTypes } from '../ProviderSearch.CoreLibraries/components/utils';
import './Home.scss';

interface TabHomeProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const TabPanel = (props: TabHomeProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>{children}</>
            )}
        </div>
    );
}

const tabProps = (index: number) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Home = () => {
    const [searchParams] = useSearchParams();
    const providerData = providerSelector;
    const selectedProviderType = useSelector(providerData.selectedProviderType);
    const showResults = useSelector(providerData.showResults);
    const selectedNPI = useSelector(providerData.selectedNPI);
    const hasError = useSelector(providerData.hasError);
    const hasMessage = useSelector(providerData.hasMessage);
    const coverageYears = useSelector(providerData.coverageYearData);
    const isLoading = useSelector(providerData.isLoading);
    const tabIndex = useSelector(providerData.tabIndex);
    const selectedUSAState = useSelector(providerData.selectedUSAState);
    const selectedCorevageYear = useSelector(providerData.selectedCoverageYear);
    const dispatch = useDispatch();
    const [value, setValue] = React.useState(1);
    const featureFlagForChat = handleFeatureFlagForChat();

    useEffect(() => {
        setValue(tabIndex)
    }, [tabIndex]);

    useEffect(() => {
        if (selectedNPI) {
            dispatch(updateSearch({ selectedNPI: null }));
        }
    }, [selectedNPI]);

    useEffect(() => {
        if (!coverageYears) {
            dispatch(getPlanYearSwitch());
        }
    }, [coverageYears]);

    useEffect(() => {
        const params: { [key: string]: any; } = Object.fromEntries([...searchParams]);
        if (Object.keys(params).length > 0) {
            params.pageIndex = Number(params.pageIndex);
            params.withinmiles = Number(params.withinmiles);
            params.tabIndex = Number(params.tabIndex);
            params.selectedCoverageYear = Number(params.selectedCoverageYear);

            switch (params.providerCategory) {
                case "1":
                    params.selectedDoctorType = "Primary Care Provider (PCP)";
                    params.providerCategory = [Number(params.providerCategory)];
                    break;
                case "2":
                    params.selectedDoctorType = params.specialization;
                    params.providerCategory = [Number(params.providerCategory)];
                    break;
                case "7":
                    params.selectedDoctorType = "Outpatient Mental / Behavioral Health";
                    params.providerCategory = [Number(params.providerCategory)];
                    break;
                case "1,2,7":
                    params.selectedDoctorType = "All Doctors";
                    params.providerCategory = [1, 2, 7];
                    break;
                case "3":
                case "4":
                case "5": {
                    const facilityType = [...dataCategoTypeForFacilities.entries()].filter((category) => {
                        return category[1] === Number(params.providerCategory)
                    });
                    if (facilityType.length > 0) {
                        params.facilityType = facilityType[0][0]
                    }
                    params.selectedProviderType = 2;
                    params.providerCategory = [Number(params.providerCategory)];
                    break;
                }
                case "3,4,5":
                    params.providerCategory = [3, 4, 5];
                    params.selectedProviderType = 2;
                    params.facilityType = 'All Facilities';
                    break;
                case "6":
                    params.selectedProviderType = 5;
                    params.providerCategory = [Number(params.providerCategory)];
                    break;
                default:
                    break;
            }

            const newParams = formatSearch(params);
            dispatch(updateFilters(newParams));
        }
    }, [searchParams]);

    useEffect(() => {
        const urlParam = Number(searchParams.get('providertype'));
        const isValidParam = providerTypes.some( pT => pT.value == urlParam );

        if (isValidParam){
            dispatch(selectProviderType(urlParam));
        }
    }, [searchParams]);

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        dispatch(setTabIndex(newValue));
    };

    const mobileConditionForShowGoTop = showResults === 2 ||
        (selectedProviderType === 3 && selectedUSAState && selectedCorevageYear) ||
        (selectedProviderType === 4 && selectedUSAState && selectedCorevageYear);

    return (
        <>
            <HeaderFindAProvider />

            <div className='home-tabs'>
                <Tabs value={value} onChange={handleChange} aria-label="Search tabs" >
                    <Tab label="Guided Search" {...tabProps(0)} />
                    <Tab label="Quick Search" {...tabProps(1)} />
                    {
                        featureFlagForChat && <Tab label="Chat" {...tabProps(2)} />
                    }
                </Tabs>
            </div>

            <TabPanel value={value} index={0}>
                <ProviderTypesContainer selectedProviderType={selectedProviderType} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <QuickSearchContainer />
            </TabPanel>
            {
                featureFlagForChat && (
                    <TabPanel value={value} index={2}>
                        <ChatContainer />
                    </TabPanel>
                )
            }

            {
                showResults === 2 && (
                    <>
                        {
                            tabIndex === 0 && (
                                <FiltersSearchContainer selectedProviderType={selectedProviderType} />
                            )
                        }

                        {!isLoading ? (
                            <>
                                <ResultActions />
                                <SearchResults />
                            </>
                        ) : (
                            <div className='spinner-results'>
                                <Spinner />
                            </div>
                        )}
                    </>
                )
            }

            {
                hasError && (
                    <AlertContainer severity='error' message={hasError} title='Server Error' />
                )
            }

            {
                hasMessage && (
                    <AlertContainer severity='success' message={hasMessage} />
                )
            }

            {
                <GoTopButton
                    desktopCondition={true}
                    mobileCondition={!!(mobileConditionForShowGoTop)}
                    mobileScrollTo={300}
                    desktopScrollTo={500}
                />
            }
        </>
    )
}

export default Home;