import { asteriskAvaHmoPlanName } from './avaHmo';
import { ILocationType } from "./models/LocationType";
import { IProviderDataType } from "./models/ProviderDataType";
import { PhoneNumber } from "../common/widgets";

export const sortValues = [
    {
        value: 'qualityrating,distance',
        text: 'Rating'
    },
    {
        value: 'distance,name,lastname',
        text: 'Distance'
    },
    {
        value: 'name,lastname',
        text: 'Name'
    }
];

export const sortValuesDoctor = [
    {
        value: 'qualityrating,distance',
        text: 'Rating'
    },
    {
        value: 'distance,name,lastname',
        text: 'Distance'
    },
    {
        value: 'name,lastname',
        text: 'First Name'
    },
    {
        value: 'lastname, name',
        text: 'Last name'
    }
]

export const sortValuesInternal = [
    {
        value: 'distance,name,lastname',
        text: 'Distance'
    },
    {
        value: 'name,lastname',
        text: 'Name'
    },
    {
        value: 'qualityrating,distance',
        text: 'Rating'
    }
];

export const miles = [
    {
        value: 1,
        text: '1 Mile'
    },
    {
        value: 5,
        text: '5 Miles'
    },
    {
        value: 10,
        text: '10 Miles'
    },
    {
        value: 20,
        text: '20 Miles'
    },
    {
        value: 30,
        text: '30 Miles'
    },
    {
        value: 50,
        text: '50 Miles'
    }
];

export const providerForm = [1, 2, 5];

export const provider = {
    acceptingNewPatients: true,
    badge: 0,
    classDescription: "Primary Care Physician",
    effectiveDate: "01/01/2018",
    entityTypeCode: "1",
    entityTypeDescription: "Individual",
    firstName: "A",
    gender: "Male",
    imageLink: "",
    isPCP: true,
    isSpecialist: false,
    language: "English",
    lastName: "Jaffer",
    locations: [{
        addressLine1: "1011 E Devonshire Ave",
        addressLine2: "Ste 102",
        addressType: "OFFICE 1",
        city: "Hemet",
        countyName: "Riverside",
        distance: 0,
        homeVisitProvDisclaimer: "",
        hospitalServices: [],
        isMobile: false,
        isVirtual: false,
        latitude: 33.75048,
        longitude: -116.96157,
        medicalGroupsParticipated: [{
            acceptingNewPatients: true,
            code: "1",
            hospitals: ["HEMET VALLEY MED CTR", "MENIFEE VALLEY MED CTR"],
            joiningNetwork: "01/01/2018",
            medicalGroupCode: "PMHV",
            medicalGroupDescription: "Optum Care Network - Hemet Valley",
            name: "Optum Care Network - Hemet Valley",
            panelDescription: "Accepting New (All) Patients",
            participatedNetworks: [{
                pbpInfo: [{
                    pbpName: "My Choice (HMO)",
                    pbpType: "Non-Virtual",
                    pbpid: "001",
                    planYear: "2022",
                }],
                planCode: null,
                planEffectiveDate: null,
                planTerminationDate: null,
                planYear: null
            }],
            providerID: "PMHV100007",
            specialties: ["Internal Medicine"]
        }],
        networkCode: "",
        phoneNumber: "9516582218",
        practiceName: "Altamed Health Services",
        state: "CA",
        zipCode: "92543",
    }],
    middleName: "Kareem",
    npi: "1225025422",
    numberOfMedicalGroupsThisProviderHas: 1,
    organizationName: "",
    otherLanguages: [],
    pcpSpecialities: ["Internal Medicine"],
    plans: [],
    specialistSpecialties: [],
    specialityDescription: "Internal Medicine",
    suffix: "MD",
    terminationDate: "",
    zipCodes: ["92543"]
};

export const emailDetailsBody = (locations: ILocationType[]) => {
    const listOfProviders = locations.map(
        (location: ILocationType) => "<tr><td class = \"table - entries\"><p>".concat(location.addressLine1, " ", location.city, ",", location.state, "</p></td><td class = \"table - entries\"><p>", PhoneNumber(location.phoneNumber), "</p></td></tr>"))

    let result = '';
    listOfProviders.forEach((provider: string) => { result = result.concat(provider); });
    return result;
}

export const emailBody = (providersToShow: IProviderDataType[]) => {
    const listOfProviders = providersToShow.map((provider: IProviderDataType) =>
        provider.firstName === '' ?
            "<tr><td class = \"table - entries\"><p>".concat(provider.organizationName, "</p></td><td class = \"table - entries\"><p>", provider.specialityDescription, "</p></td><td class = \"table - entries\"><p>", provider.locations[0].addressLine1, " ", provider.locations[0].city, ",", provider.locations[0].state, "</p></td><td class = \"table - entries\"><p>", PhoneNumber(provider.locations[0].phoneNumber), "</p></td></tr>") :
            "<tr><td class = \"table - entries\"><p>".concat(provider.firstName, " ", provider.lastName, "</p><td class = \"table - entries\"><p>", provider.specialityDescription, "</p></td></td class = \"table - entries\"><td><p>", provider.locations[0].addressLine1, " ", provider.locations[0].city, ",", provider.locations[0].state, "</p></td><td class = \"table - entries\"><p>", PhoneNumber(provider.locations[0].phoneNumber), "</p></td></tr>"))
    let result = '';

    listOfProviders.map((provider: string) => { result = result.concat(provider); });
    return (result);
}

export const mapContainerStyle = {
    width: '100%',
    height: '100%'
};

export const createPayload = (message: String, firstName: String, lastName: String, npi: Number) => {
    return `${message}#https://providersearch.alignmenthealthplan.com/manual?npi=${npi}#${firstName} ${lastName}`
}

export const sleep = (microSeconds: number) => new Promise((callBack) => setTimeout(callBack, microSeconds));

export const providerTypes = [
    {
        value: 1,
        text: 'Doctors',
        icon: 'doctor',
    },
    {
        value: 2,
        text: 'Facilities',
        icon: 'facilities',
    },
    {
        value: 3,
        text: 'Dental',
        icon: 'dental',
    },
    {
        value: 4,
        text: 'Vision',
        icon: 'vision',
    },
    {
        value: 5,
        text: 'Other Specialty Services',
        icon: 'services',
    }
];

export const formatMedicalGroups = (medicalGroupsData: any[]) => {
    let medicalGroupFilterDataSource: any = [];

    let ipaDescriptionToIPAIdsMap = new Map();
    medicalGroupsData.forEach(
        ipaItem => {
            let existingValue = ipaDescriptionToIPAIdsMap.get(ipaItem.desc);
            if (existingValue) {
                ipaDescriptionToIPAIdsMap.set(ipaItem.desc, existingValue + ',' + ipaItem.id);
            }
            else {
                ipaDescriptionToIPAIdsMap.set(ipaItem.desc, ipaItem.id);
            }
        }
    );

    ipaDescriptionToIPAIdsMap.forEach(
        (value, key) => {
            if (key && value) {
                let arrValue = value.split(',');
                arrValue.sort();
                const newValue = arrValue.join(',');
                medicalGroupFilterDataSource.push({ text: key, value: newValue })
            }
        }
    );

    return medicalGroupFilterDataSource;
}

export const formatMedicalPlans = (medicalPlansData: any[]) => {
    let medicalMap: any = [];
    let healthPlanMapData = new Map();

    medicalPlansData.length > 0 &&
        medicalPlansData.map(
            pbpItem => {
                healthPlanMapData.has(pbpItem.year)
                    ? !healthPlanMapData.get(pbpItem.year).includes(pbpItem.name + ' ' + pbpItem.id) &&
                    healthPlanMapData.set(pbpItem.year, healthPlanMapData.get(pbpItem.year) + ' ! ' + pbpItem.name + ' ' + pbpItem.id)
                    : healthPlanMapData.set(pbpItem.year, pbpItem.name + ' ' + pbpItem.id)
            }
        );

    Array.from(healthPlanMapData)
        .map((h: any) => h[1].split(' ! ')
            .map((hh: any) => {
                let arr = hh.split(' ');
                let code = arr[arr.length - 1].split('-');
                code.shift();
                arr[arr.length - 1] = code.join('-');
                let txt = arr.join(' ');
                medicalMap.push(
                    {
                        year: h[0],
                        value: `${h[0]}_${hh}`,
                        text: asteriskAvaHmoPlanName(txt)
                    }
                );
            })
        );

    return medicalMap;
}

export const needResetIndexPage = (pageNum: number) => {
    return pageNum !== 1;
}

export const checkIfIsNcNv23 = (year: number | null, state: any) => {
    return (year === 2023) && (state === 'North Carolina' || state === 'Nevada');
}

export const sanitizeParameter = (sanitizeString: string) => {
    const newSanitizeString = sanitizeString.replace("&", "%26");
    return newSanitizeString;
}

export const printDocument = () => window.print();

export const scrollDown = (maxHeight: number) => {
    const desplace = 200;
    let scrollTop = document.documentElement.scrollTop;
    const interval = setInterval(() => {
        scrollTop = scrollTop + desplace;
        window.scrollTo(0, scrollTop)
        if (scrollTop >= maxHeight) {
            clearInterval(interval);
        }
    }, 20)
}

export const pcpSpecialities = ['Internal Medicine', 'Family Medicine', 'General Practice', 'Geriatrics'];
export const specialties = ['All Doctors', 'Primary Care Provider (PCP)', 'Outpatient Mental Health',"Allergy","Acupuncturist",
    "AddictionMedicine","Allergy&Immunology","Audiology","BariatricMedicine/Surgery","Cardiology","Chiropractic","CLINICALPSYCHOLOGIST",
    "Colon&RectalSurgery","CardiothoracicSurgery","Dermatology","DiagnosticRadiology","Endocrinology","FamilyMedicine","Gastroenterology",
    "Geriatrics","GeneralPractice","GeneralSurgery","Gynecology","Gynecology/oncology","GynecologicSurgery","Hematology","Hepatology",
    "Hematology/oncology","Hospice","HandSurgery","InfectiousDiseases","InternalMedicine","InterventionalCardiology",
    "LICENSEDCLINICALSOCIALWORKER","Marriage/familyCounselor","MaxillofacialSurgery","Counselor-MentalHealth","SocialWorker","Neurology",
    "Nephrology","NuclearMedicine","NeuromusculoskeletalMedicine","Neuropsychology","NeurologicalSurgery","Obstetrics&Gynecology",
    "OccupationalTherapy","Oncology","Ophthalmology","Optometry","OrthopedicSurgery","Otology","Otolaryngology","Psychiatry",
    "PalliativeMedicine","PhysicalMedicine","Physiatry","PhysicalMedicine&Rehab","PainManagement","Podiatry","PlasticAndReconstructiveSur",
    "Surgery-Podiatry","Psychology","PhysicalTherapy","PulmonaryDiseases","Psychoanalysis","Psychotherapy","Radiology","Rheumatology",
    "RadiationOncology","SleepMedicine","SurgicalOncology","SportsMedicine","Speech-LanguagePathology","ThoracicSurgery","Urology",
    "Urology/gynecology","Surgery-Urological","Vascular&InterventionalRadi","VascularSurgery"];

export let handleFeatureFlagForHardcodingSpecialties = () => true;

export let handleFeatureFlagForChat = () => false;

export const getFullAddress = (location: ILocationType) => {
    return `${location.addressLine1} ${location.addressLine2} ${location.city}, ${location.state} ${location.zipCode}`;
};

export const getPhoneNumberWithFormat = (phoneNumber: string) => {
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }

    return null;
};

export enum ProviderContentType {
    Provider,
    Groups,
    Locations,
}

export const formatSearch = (params) => {
    const root = {
        providerCategory: params.providerCategory,
        npi: params.selectedNPI,
        visitType: params.selectedVisitType,
        gender: params.selectedGender,
        language: params.selectedLanguage,
        acceptNewPatients: params.selectedAcceptNewPatients,
        serviceType: params.selectedServiceType,
        providerAffiliation: params.selectedProviderAffiliation,
        selectedProviderType : params.selectedProviderType ?? 1,
        emailSearch: true,
        isCommingBackHome: true,
        tabIndex: params.tabIndex,
    };

    let response;

    if (params.tabIndex === 0) {
        response = {
            ...root,
            guidedSearch: {
                specialization: params.specialization,
                location: params.location,
                searchTerm: params.searchTerm,
                sortby: params.sortby,
                medicalGroup: params.selectedMedicalGroup,
                plan: params.selectedPlan,
                withinmiles: params.withinmiles,
                pageIndex: params.pageIndex,
                selectedDoctorType: params.selectedDoctorType ?? '',
                facilityType: params.facilityType ?? '',
                selectedCoverageYear: params.selectedCoverageYear ?? '',
            }
        }
    } else {
        response = {
            ...root,
            quickSearch: {
                location: params.location,
                searchTerm: params.searchTerm,
                sortby: params.sortby,
                withinmiles: params.withinmiles,
                pageIndex: params.pageIndex,
            }
        }
    }

    return response;
}

export const MEDICAID_INDICATOR_TEXT = 'Accepting Medicare and Medicaid';
